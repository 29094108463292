import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`BUILD REALTIME FEATURES YOUR USERS LOVE
We solve complex realtime infrastructure problems so you don’t have to.`}</p>
    <p>{`POWERFUL FEATURES TO HELP YOU BUILD INTERACTIVE APPS
DISCOVER MORE FEATURES
Managed websocket connections
Whether you have five concurrent connections or a million, our infrastructure scales to your needs.`}</p>
    <p>{`Flexible pub/sub messaging
Instantly update browsers, mobile devices, and IoT gadgets with our simple event-based API.`}</p>
    <p>{`Live user lists (presence)
Showcase the online or offline status of your users in realtime with crystal-clear presence indicators.`}</p>
    <p>{`Privacy and security
Private channels let your existing auth system determine who can subscribe. End-to-end encryption prevents anyone from reading your messages—even us.`}</p>
    <p>{`CHANNELS IS VERSATILE
SOCIAL
Update your app’s UI in realtime to increase user engagement.`}</p>
    <p>{`COLLABORATION
Instantly update browsers, mobile devices, and IoT gadgets with our event-based API.`}</p>
    <p>{`LOCATION
Update journeys, deliveries and more in realtime to keep users informed.`}</p>
    <p>{`REALTIME GRAPHS
Update your UI as soon as data is available so users don’t miss a thing.`}</p>
    <p>{`What you can build with Channels
Today’s users expect realtime experiences—without clicking or dragging to refresh. From customer support chat apps and realtime polling to realtime mapping and live sports commentary, Channels empowers all developers to create powerful realtime features at scale without worrying about infrastructure.`}</p>
    <p>{`Realtime features
OUR POPULAR USE CASES
Discover how more than 250,000 developers use Channels to build better products.`}</p>
    <p>{`Collaboration and social features
Social & Collaboration
Use Channels to easily create realtime activity feeds for your new social network, support live comments and interactions for a sports event, or launch a poll with results that update in realtime.`}</p>
    <p>{`Realtime Location features
Location
Display the realtime location of your food delivery service, share dynamic geolocation data with a friend, or even create a geofence that triggers location-based alerts.`}</p>
    <p>{`Realtime AR/VR features
AR/VR/Gaming
Deliver a realistic feeling to your users and create a stronger community by adding realtime multi-user interactions to your games and apps.`}</p>
    <p>{`Realtime Trading
Realtime Trading
Allow traders to execute as fast as opportunities present themselves and display trades and data visualizations in realtime using Channels for your trading platform.`}</p>
    <p>{`GETTING STARTED WITH PUSHER IS FREE
Join more than 250,000 happy developers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      